<template>
  <div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { cleanEntityToSend } from '../../utils/form'
import {capitalize} from '../../utils/filter'

import api from '../../libs/axios'
import Vue from 'vue'
import ToastificationContent from '../../components/toastification/ToastificationContent.vue'
import i18n from '../../libs/i18n'

export default {
  components: {},
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const upgrade = async () => {
      return new Promise((resolve, reject) => {
        api.post('/upgrade/v1_to_v2')
            .then(response => {
              console.log(response.data)


              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: capitalize(i18n.t('success')),
                  icon: 'check',
                  text: "xxx",
                  variant: 'success'
                },
              })

              resolve(response)
            })
            .catch(error => {
              console.log('Error', error)
              reject(error)
            })
      })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    upgrade()

    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>